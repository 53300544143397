import { Dispatch } from "react";
import {
  GetTimelineAction,
  GetCalendarTimelineAction,
  FetchTimelineParams,
  PlanningSystemActionType,
  GetTimelineActionResult,
  GetCalendarTimelineActionResult,
  FetchTimelineResponse,
  FetchAllOperationActivityParams,
  GetAllOperationActivitiesResult,
  GetAllOperationActivitiesAction,
  CreateOaParams,
  CreateOaResult,
  CreateOaAction,
  ResponseStatus,
  CreateOaResponseErrors,
  PatchOaParams,
  EditOaResult,
  EditOaAction,
  DeleteOaResponseErrors
} from "app/planningSystem/models";
import { fetchTimeline } from "app/planningSystem/services/timeline";
import {
  fetchAllOperationActivity,
  createOperationActivity,
  editOperationActivity,
  deleteOperationActivity
} from "app/planningSystem/services/operationActivity";
import { timelineToCalendarTimeline } from "app/planningSystem/utils/helpers";
import { setFeedback } from "app/feedback/actions";
import { FeedbackStatus } from "app/feedback/models";
import { store } from "app/store";

/**
 * @description request the timeline from the APIs
 * @param params
 */
export const getTimelineAction = (
  params: FetchTimelineParams
): GetTimelineActionResult => {
  return async (dispatch: Dispatch<GetTimelineAction>) => {
    try {
      const payload = await fetchTimeline(params);
      dispatch({
        type: PlanningSystemActionType.GetTimeline,
        payload
      });
      return Promise.resolve(payload);
    } catch (e) {
      return Promise.resolve(null);
    }
  };
};

/**
 * @description takes the timelines from the store,
 * remap them to CalendarTimeline (used to be rendered in the timeline)
 * and save them into the store
 * @param timelines
 */
export const getCalendarTimelineAction = (
  timelines: FetchTimelineResponse
): GetCalendarTimelineActionResult => {
  return async (dispatch: Dispatch<GetCalendarTimelineAction>) => {
    try {
      const payload = await timelineToCalendarTimeline(timelines);
      dispatch({
        type: PlanningSystemActionType.GetCalendarTimeline,
        payload
      });
      return Promise.resolve(payload);
    } catch (e) {
      return Promise.resolve([]);
    }
  };
};

export const getAllOperationActivitiesAction = (
  params?: FetchAllOperationActivityParams
): GetAllOperationActivitiesResult => {
  return async (dispatch: Dispatch<GetAllOperationActivitiesAction>) => {
    try {
      const payload = await fetchAllOperationActivity(params);
      dispatch({
        type: PlanningSystemActionType.GetOperationActivities,
        payload
      });
      return payload;
    } catch (e) {
      return Promise.resolve({ oas: [], pagination: {} });
    }
  };
};

export const createOperationActivityAction = (
  params: CreateOaParams
): CreateOaResult => {
  return async (dispatch: Dispatch<CreateOaAction>) => {
    try {
      const payload = await createOperationActivity(params);

      if (payload && "status" in payload) {
        if (payload.status === ResponseStatus.OK) {
          store.dispatch(
            setFeedback(
              "Success",
              FeedbackStatus.SUCCESS,
              "Operation activity created"
            )
          );
        } else if (payload.status === ResponseStatus.CREATED_WITH_WARNINGS) {
          store.dispatch(
            setFeedback(
              "CREATED WITH WARNING",
              FeedbackStatus.WARNING,
              payload.warnings[0]
            )
          );
        }
      } else if (payload && "details" in payload) {
        const errorMessage =
          (typeof (payload as CreateOaResponseErrors).details === "string" &&
            ((payload as CreateOaResponseErrors).details as string)) ||
          "Validation Error";
        store.dispatch(
          setFeedback("Error", FeedbackStatus.ERROR, errorMessage)
        );
      }

      return payload;
    } catch (error) {
      return Promise.resolve(null);
    }
  };
};

export const editOperationActivityAction = (
  uuid: string,
  params: PatchOaParams
): EditOaResult => {
  return async (dispatch: Dispatch<EditOaAction>) => {
    try {
      const { data, status } = await editOperationActivity(uuid, params);

      if (data && typeof data !== "string" && "details" in data) {
        const errorMessage =
          (typeof (data as CreateOaResponseErrors).details === "string" &&
            ((data as CreateOaResponseErrors).details as string)) ||
          "Validation Error";
        store.dispatch(
          setFeedback("Error", FeedbackStatus.ERROR, errorMessage)
        );
      } else if (status === 204 || status === 200) {
        store.dispatch(
          setFeedback(
            "Success",
            FeedbackStatus.SUCCESS,
            "Operation activity modified"
          )
        );
      }
      return data;
    } catch (error) {
      return Promise.resolve(null);
    }
  };
};

export const deleteOperationActivityAction = (uuid: string): EditOaResult => {
  return async (dispatch: Dispatch<EditOaAction>) => {
    try {
      const { data, status } = await deleteOperationActivity(uuid);

      if (data && typeof data !== "string" && "details" in data) {
        const errorMessage =
          (typeof (data as DeleteOaResponseErrors).details === "string" &&
            ((data as DeleteOaResponseErrors).details as string)) ||
          "Validation Error";
        store.dispatch(
          setFeedback("Error", FeedbackStatus.ERROR, errorMessage)
        );
      } else if (status === 204 || status === 200) {
        store.dispatch(
          setFeedback(
            "Success",
            FeedbackStatus.SUCCESS,
            "Operation activity deleted"
          )
        );
      }
      return data;
    } catch (error) {
      return Promise.resolve(null);
    }
  };
};
